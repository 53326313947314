import { addTelemetryEvent } from "./telemetry";
import { onCLS, onFID, onINP, onLCP, onTTFB } from "web-vitals";

const setUpWebVitalsMetrics = () => {
  onFID(addToTelemetryQueue);
  onTTFB(addToTelemetryQueue);
  onLCP(addToTelemetryQueue);
  onCLS(addToTelemetryQueue);
  onINP(addToTelemetryQueue);
};

export default setUpWebVitalsMetrics;

function addToTelemetryQueue(metric) {
  const duration_ms = metric.name === "CLS" ? 0 : metric.delta;
  const event = {
    ...metric,
    name: `cwv.${metric.name.toLowerCase()}`,
    duration_ms
  };
  addTelemetryEvent(event);
}
