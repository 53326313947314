import Language from "../../Interfaces/Language";

export const fr_ca: Language = {
  help: "Vous avez besoin d'aide?",
  helpCenter: "Pages d'aide",
  expect: "Ce à quoi il faut s'attendre lors de ces tests",
  faq: "Foire aux questions",
  faqLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853499-foire-aux-questions",
  copyright: "Droit d'auteur",
  copyrightNotices: "Avis de droit d'auteur",
  copyrightNoticesLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994362-avis-de-droit-d-auteur",
  terms: "Conditions d'utilisation",
  termsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994194-conditions-d-utilisation",
  privacy: "Déclaration de confidentialité",
  privacyLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990049-politique-de-confidentialite",
  dmca: "Politique relative à la DMCA",
  dmcaLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853816-politique-relative-a-la-dmca",
  accommodations: "Handicap / Accommodements",
  accommodationsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994429-handicap-accessibilite",
  technicalSupport: "Soutien technique",
  chatSupport:
    "Pour obtenir une assistance, rendez-vous dans le coin inférieur droit de cette page pour dialoguer en ligne avec notre équipe."
};
