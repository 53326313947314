import Language from "../../Interfaces/Language";

export const es_la: Language = {
  help: "¿Necesita ayuda?",
  helpCenter: "Servicio de ayuda",
  expect: "¿Cómo son las pruebas?",
  faq: "Preguntas frecuentes",
  faqLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869773-preguntas-frecuentes",
  copyright: "Derechos de autor",
  copyrightNotices: "Avisos de derechos de autor",
  copyrightNoticesLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994360-aviso-de-derechos-de-autor",
  terms: "Términos de uso",
  termsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869769-terminos-de-uso",
  privacy: "Declaración de privacidad",
  privacyLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869768-politica-de-privacidad",
  dmca: "Política DMCA",
  dmcaLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7869777-politica-dmca",
  accommodations: "Discapacidad/Adaptaciones",
  accommodationsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6989970-discapacidad-adaptaciones",
  technicalSupport: "Soporte técnico",
  chatSupport:
    "Si tiene alguna pregunta, chatee con nuestro equipo en la parte inferior derecha de esta página."
};
