import moment from "moment-timezone";
import { zonedTimeToUtc, formatInTimeZone } from "date-fns-tz";

export const getNewDate = (str: string): Date => {
  if (!str || typeof str !== "string") {
    return new Date(str);
  }
  return new Date(str.replace(/-/g, "/"));
};

const languageToLocaleLibrary: { [key: string]: string } = {
  spanish: "es",
  french: "fr",
  german: "de",
  portuguese: "pt",
  "portuguese-brazil": "pt-BR",
  turkish: "tr",
  "spanish-spain": "es",
  "french-canada": "fr-CA",
  chinese: "zh-CN",
  japanese: "ja",
  korean: "ko",
  italian: "it"
};

export const formatWithTranslation = async (
  date: Date,
  timezone: string,
  format: string,
  language: string
) => {
  const localeCode = languageToLocaleLibrary[language];
  let locale;

  try {
    if (localeCode) {
      locale = await import(`date-fns/locale/${localeCode}`);
    }
  } catch (error) {
    console.error(`Locale not supported: ${localeCode}`);
  }

  // fallback to English if the locale is not supported, or if the locale is not specified
  return formatInTimeZone(date, timezone, format, {
    locale: locale ? locale.default : undefined
  });
};

export const convertDateTimezone = async (
  date: string,
  timezone: string,
  isAULocale: boolean | null = false,
  language: string = "english"
): Promise<string> => {
  let formattedDate: string;

  // the eventEventExpirationDate is stored in the database in utc time so we need to convert it to a date
  const utcDateTime = zonedTimeToUtc(date, "UTC");

  // if the `timezone` value somehow is falsy
  // fallbacks to the old way of showing either AET or PT timezone based on the company's selected locale
  // otherwise it should be the timezone set in the company settings or the job settings
  const defaultDisplayTimeZone = isAULocale
    ? "Australia/Sydney"
    : "America/Los_Angeles";

  const displayedTimezone = timezone || defaultDisplayTimeZone;

  formattedDate = await formatWithTranslation(
    utcDateTime,
    displayedTimezone,
    "EEEE, MMMM d, yyyy @ h:mm a z",
    language
  );

  // Format the newly formatted date with custom timezone abbreviation if needed
  let customFormattedDateTimezoneAbbreviation: string =
    getCustomTimezoneWithNewAbbreviation(formattedDate, timezone);

  return customFormattedDateTimezoneAbbreviation;
};

export const formatTime = (time: number): string => {
  let roundedDownTime = Math.floor(time);
  let minutes: number | string = Math.floor(roundedDownTime / 60);
  let seconds: number | string = roundedDownTime - minutes * 60;
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${minutes}:${seconds}`;
};

export const shuffleArray = (a: any) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

// eslint-disable-next-line no-script-url
const blacklistedTerms = ["javascript:", "data:"];
export const validateCandidateInput = (
  input: string,
  requiredText: string,
  maxLength: number
) => {
  if (
    (requiredText && input.indexOf(requiredText) < 0) ||
    (maxLength && input.length > maxLength)
  ) {
    return false;
  } else if (
    blacklistedTerms.reduce(
      (bool: boolean | undefined, blacklistedTerm: string) => {
        return bool || input.indexOf(blacklistedTerm) > -1;
      },
      false
    )
  ) {
    return false;
  }
  return true;
};

export const encodeDangerousCharacters = (stringToEval: string) => {
  return stringToEval
    .split("")
    .reduce((encodedString: string, char: string) => {
      return `${encodedString}${char.replace(
        /[^a-z0-9 ]/i,
        encodeURIComponent(char)
      )}`;
    }, "");
};

const getExpirationDateUTCInMilliseconds = (expirationDate: string) => {
  const expirationDateUTC = zonedTimeToUtc(expirationDate, "UTC");
  const expirationDateUTCInMilliseconds = expirationDateUTC.getTime();

  return expirationDateUTCInMilliseconds;
};

export const isEventExpired = (expirationDate: string | null): boolean => {
  // if an event does not have an expiration time, then return false immediately
  if (expirationDate === null) {
    return false;
  }

  // get the current time in UTC milliseconds
  const currentUTCDateInMilliseconds = new Date().getTime();

  // get the expiration time in UTC milliseconds
  const expirationDateUTCInMilliseconds =
    getExpirationDateUTCInMilliseconds(expirationDate);

  // compare the two converted UTC millisecond values to determine if the event is expired or not.
  if (currentUTCDateInMilliseconds > expirationDateUTCInMilliseconds) {
    return true;
  }

  return false;
};

export const checkIsOneHourBeforeExpiration = (
  expirationDate: string | null
) => {
  let isOneHourBeforeExpiration: boolean = false;

  if (expirationDate !== null) {
    // get the current time in UTC milliseconds
    const currentUTCDateInMilliseconds = new Date().getTime();

    // get the expiration time in UTC milliseconds
    const expirationDateUTCInMilliseconds =
      getExpirationDateUTCInMilliseconds(expirationDate);

    const hour: number = 1000 * 60 * 60;

    isOneHourBeforeExpiration =
      expirationDateUTCInMilliseconds - currentUTCDateInMilliseconds > 0 &&
      expirationDateUTCInMilliseconds - currentUTCDateInMilliseconds < hour;
  }

  return isOneHourBeforeExpiration;
};

export const getBase64FromFile = (file: any) =>
  new Promise((resolve, reject) => {
    // this will return a promise, that can then be resolved with a function to send data to DB, or whatever else.
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

// initialize the subTestId arrays, used to determine which test to display
export const multipleChoiceTests: string[] = [
  "1",
  "4",
  "21",
  "22",
  "27",
  "28",
  "29",
  "33",
  "39",
  "40",
  "41",
  "42",
  "44",
  "45",
  "55",
  "56",
  "57",
  "58",
  "59",
  "65",
  "67",
  "68",
  "69",
  "70",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "78",
  "79",
  "83",
  "84",
  "85",
  "86",
  "87",
  "93",
  "94",
  "95",
  "96",
  "97",
  "100",
  "101",
  "102",
  "103",
  "104",
  "105",
  "106",
  "107",
  "108",
  "111",
  "113",
  "114",
  "115",
  "116",
  "118",
  "124",
  "125",
  "126",
  "127",
  "128",
  "129",
  "130",
  "131", // adaptive CCAT
  "133",
  "134",
  "142",
  "177",
  "178",
  "187"
  // LAST LINE - DO NOT MOVE THIS COMMENT multipleChoiceTests
];

export const personalityTests: string[] = [
  "7",
  "19",
  "20",
  "35",
  "43",
  "52",
  "88",
  "89",
  "90",
  "91",
  "98",
  "99",
  "152",
  "156",
  "157",
  "155",
  "185",
  "186"
  // LAST LINE - DO NOT MOVE THIS COMMENT personalityTests
];
export const gameTests: string[] = [
  "92",
  "117",
  "165"
  // LAST LINE - DO NOT MOVE THIS COMMENT gameTests
];
export const typingTests: string[] = [
  "23"
  // LAST LINE - DO NOT MOVE THIS COMMENT typingTests
];
export const tenKeyTests: string[] = [
  "24"
  // LAST LINE - DO NOT MOVE THIS COMMENT tenKeyTests
];
export const mrabCastTests: string[] = [
  "10",
  "12",
  "13",
  "18"
  // LAST LINE - DO NOT MOVE THIS COMMENT mrabCastTests
];
export const CLIKTests: string[] = [
  "34",
  "135"
  // LAST LINE - DO NOT MOVE THIS COMMENT CLIKTests
];
export const nonMobileTests: string[] = [
  "23",
  "24",
  "34",
  "135"
  // LAST LINE - DO NOT MOVE THIS COMMENT nonMobileTests
];
export const rcatTests: string[] = [
  "188",
  "189"
  // LAST LINE - DO NOT MOVE THIS COMMENT rcatTests
];
export const revelianSubTests = [
  "109",
  "110",
  "112",
  "119",
  "120",
  "121",
  "122",
  "123",
  "132",
  "136",
  "137",
  "138",
  "139",
  "140",
  "141",
  "143",
  "144",
  "145",
  "146",
  "150",
  "151",
  "153",
  "154",
  "158",
  "159",
  "160",
  "161",
  "162",
  "163",
  "164",
  "166",
  "167",
  "168",
  "169",
  "170",
  "171",
  "172",
  "173",
  "174",
  "175",
  "176",
  "179",
  "180",
  "181",
  "182",
  "183",
  "184",
  "188",
  "189",
  "190",
  "191",
  "192",
  "193",
  "194",
  "195",
  "196",
  "197",
  "198",
  "199",
  "200",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "211",
  "212",
  "213",
  "214",
  "215"
  // LAST LINE - DO NOT MOVE THIS COMMENT revelianSubTests
];

export const getAllTests = () => {
  const allTestsLibrary: { [key: string]: string } = {};
  multipleChoiceTests.forEach(
    testId => (allTestsLibrary[testId] = "multipleChoiceTest")
  );
  personalityTests.forEach(
    testId => (allTestsLibrary[testId] = "personalityTest")
  );
  gameTests.forEach(testId => (allTestsLibrary[testId] = "gameTest"));
  typingTests.forEach(testId => (allTestsLibrary[testId] = "typingTest"));
  tenKeyTests.forEach(testId => (allTestsLibrary[testId] = "tenKeyTest"));
  mrabCastTests.forEach(testId => (allTestsLibrary[testId] = "mrabCastTest"));
  CLIKTests.forEach(testId => (allTestsLibrary[testId] = "CLIKTest"));
  revelianSubTests.forEach(
    testId => (allTestsLibrary[testId] = "revelianSubTest")
  );
  return allTestsLibrary;
};

export const revelianTestToSubTestIdMap = [
  {
    name: "emotify",
    subTestIds: [
      "109",
      "112",
      "137"
      // LAST LINE - DO NOT MOVE THIS COMMENT emotify
    ]
  },
  {
    name: "cognify",
    subTestIds: [
      "110",
      "121",
      "122",
      "123",
      "138",
      "139",
      "140",
      "141",
      "143",
      "144",
      "145",
      "146",
      "150",
      "151",
      "153",
      "154",
      "158",
      "159",
      "160",
      "161",
      "162",
      "163",
      "164",
      "166",
      "167",
      "168",
      "169",
      "170",
      "173",
      "174",
      "183",
      "184"
      // LAST LINE - DO NOT MOVE THIS COMMENT cognify
    ]
  },
  {
    name: "safety",
    subTestIds: [
      "119",
      "120",
      "171",
      "172",
      "175",
      "176"
      // LAST LINE - DO NOT MOVE THIS COMMENT safety
    ]
  },
  {
    name: "personality",
    subTestIds: [
      "132"
      // LAST LINE - DO NOT MOVE THIS COMMENT personality
    ]
  },
  {
    name: "values",
    subTestIds: [
      "136"
      // LAST LINE - DO NOT MOVE THIS COMMENT values
    ]
  },
  {
    name: "excel",
    subTestIds: [
      "179",
      "180"
      // LAST LINE - DO NOT MOVE THIS COMMENT excel
    ]
  },
  {
    name: "word",
    subTestIds: [
      "181"
      // LAST LINE - DO NOT MOVE THIS COMMENT word
    ]
  },
  {
    name: "powerpoint",
    subTestIds: [
      "182"
      // LAST LINE - DO NOT MOVE THIS COMMENT powerpoint
    ]
  },
  {
    name: "rcat",
    subTestIds: ["188", "189"]
  },
  {
    name: "ability",
    subTestIds: [
      "190",
      "191",
      "192",
      "193",
      "194",
      "195",
      "196",
      "197",
      "198",
      "199",
      "200",
      "201",
      "202",
      "203",
      "204",
      "205",
      "206",
      "207",
      "208",
      "209",
      "210",
      "211",
      "212",
      "213",
      "214",
      "215"
      // LAST LINE - DO NOT MOVE THIS COMMENT ability
    ]
  }
  // LAST LINE - DO NOT MOVE THIS COMMENT revelianTestToSubTestIdMapNew
];

export const testsToDisplayGraphs: string[] = ["57", "67", "68", "69", "83"];

export const GDPRCountryValues = [
  "AT",
  "BE",
  "BG",
  "HR",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE"
];

export const preloadImages = async (questions: any) => {
  // eslint-disable-next-line array-callback-return
  questions.map((question: any, index: number) => {
    const img1 = new Image();
    img1.src = question.optionA;
    const img2 = new Image();
    img2.onload = () => {
      img2.src = question.optionB;
      if (index === 39) {
        return;
      }
    };
  });
};

export const isValidEmail = (emailAddress: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailAddress);
};

export const subTestIdToSubTestName: { [key: string]: string } = {
  1: "CCAT",
  2: "CCAT",
  3: "CCAT",
  4: "CBST",
  5: "CBST",
  6: "CBST",
  7: "CPI",
  8: "BMST",
  9: "BVST",
  10: "MRAB",
  11: "MRAB",
  12: "MRABe",
  13: "CAST",
  14: "Memory",
  15: "Selective Attention",
  16: "Mental Rotation",
  17: "Divided Attention",
  18: "CASTe",
  19: "CSAP",
  20: "SalesAP",
  21: "WTMA",
  22: "WTMAe",
  23: "TT",
  24: "TKT",
  25: "Excel 03",
  26: "Word 03",
  27: "Excel 07",
  28: "Word 07",
  29: "CCAT",
  30: "EPP",
  31: "CCAT",
  32: "CBST",
  33: "CCAT",
  34: "CLIK",
  35: "WPP",
  36: "CCAT",
  37: "CCAT",
  38: "CCAT",
  39: "CBSTe",
  40: "CCAT",
  41: "Excel 10",
  42: "Word 10",
  43: "EPP",
  44: "CCAT Rx",
  45: "CCAT UK2",
  46: "CCAT",
  47: "CCAT",
  48: "CCAT",
  49: "CCAT",
  50: "CCAT",
  51: "CCAT",
  52: "WPPe",
  53: "CCAT",
  54: "CCAT",
  55: "CBST CA",
  56: "CCAT CA",
  57: "CCAT",
  58: "Excel 13",
  59: "Word 13",
  60: "CCAT",
  61: "CCAT",
  62: "CCAT",
  63: "CBST",
  64: "CBST",
  65: "CBST Rx",
  66: "AIT",
  67: "UCAT",
  68: "UCATe",
  69: "UCATf",
  70: "CBST EU",
  71: "CCAT UK2 RX",
  72: "PPT 13",
  73: "UCATc",
  74: "UCATj",
  75: "UCATh",
  76: "Excel 16",
  77: "Word 16",
  78: "PPT 16",
  79: "UCATg",
  80: "CBST",
  81: "CBST",
  82: "CBST",
  83: "CBST",
  84: "UCAT Rx",
  85: "UCATp",
  86: "UCATpg",
  87: "UCATb",
  88: "EPPe",
  89: "EPPf",
  90: "EPPp",
  91: "EPPc",
  92: "GAME",
  93: "UCATr",
  94: "UCATa",
  95: "CMRA",
  96: "UCATs",
  97: "UCATm",
  98: "EPPu",
  99: "EPPg",
  100: "WTMA Rx",
  101: "CCAT",
  102: "CMRAs",
  103: "CMRAf",
  104: "UCATcz",
  105: "UCATd",
  106: "UCATu",
  109: "Emotify",
  110: "Cognify",
  111: "CBST Rx",
  112: "Emotify Rx",
  113: "CCAT",
  114: "CBST",
  115: "CMRA",
  116: "UCAT",
  117: "GAME",
  118: "UCATk",
  119: "WSP L",
  120: "WSP",
  121: "Cognify",
  122: "Cognify Rx",
  123: "Cognify Rx",
  124: "CCAT Rx",
  125: "CBST Rx",
  126: "CMRA Rx",
  127: "UCAT Rx",
  128: "Excel 16",
  129: "Word 16",
  130: "PPT 16",
  131: "CCAT cAT",
  133: "UCATc",
  134: "UCATpg",
  135: "CLIK",
  136: "WAA",
  137: "Emotify",
  138: "UCognify",
  139: "UCognify",
  140: "UCognify de",
  141: "UCognify es-es",
  142: "UCATi",
  143: "UCognify pt-br",
  144: "UCognify pt-br",
  145: "UCognify fr-ca",
  146: "UCognify fr-ca",
  150: "UCognify pt-pt",
  151: "UCognify pt-pt",
  152: "EPP ES-LA",
  153: "UCognify es-la",
  154: "UCognify es-la",
  156: "EPP ZH-CN",
  157: "EPP JA",
  155: "EPP PT-BR",
  158: "UCognify de",
  159: "UCognify ko",
  160: "UCognify ko",
  161: "UCognify tr",
  162: "UCognify tr",
  163: "Cognify tr",
  164: "Cognify tr",
  165: "GAMEes-la", // PM requests not to have a space in between the assessment and language code
  166: "UCognify fr-fr",
  167: "UCognify fr-fr",
  168: "UCognify es-es",
  169: "UCognify ja",
  170: "UCognify ja",
  171: "WSPes-la",
  172: "WSPes-la",
  173: "UCognify zh-cn",
  174: "UCognify zh-cn",
  175: "WSPpt-br",
  176: "WSPpt-br",
  177: "UCATfr-ca",
  178: "UCATes-la",
  179: "Excel 365",
  180: "Excel 365",
  181: "Word 365",
  182: "PowerPoint 365",
  183: "UCognify it",
  184: "UCognify it",
  185: "EPPit",
  186: "EPPfr-fr",
  187: "WTMAfr-ca",
  188: "RCAT",
  189: "RCAT",
  190: "CLPT-EN",
  191: "CLPTE-EN",
  192: "CLPT-EN es-es",
  193: "CLPT-EN es-la",
  194: "CLPT-EN fr-fr",
  195: "CLPT-EN fr-ca",
  196: "CLPT-EN pt-pt",
  197: "CLPT-EN pt-br",
  198: "CLPT-EN zh-cn",
  199: "CLPT-EN de",
  200: "CLPT-EN it",
  201: "CLPT-EN ja",
  202: "CLPT-EN ko",
  203: "CLPT-EN tr",
  204: "CLPTE-EN es-es",
  205: "CLPTE-EN es-la",
  206: "CLPTE-EN fr-fr",
  207: "CLPTE-EN fr-ca",
  208: "CLPTE-EN pt-pt",
  209: "CLPTE-EN pt-br",
  210: "CLPTE-EN zh-cn",
  211: "CLPTE-EN de",
  212: "CLPTE-EN it",
  213: "CLPTE-EN ja",
  214: "CLPTE-EN ko",
  215: "CLPTE-EN tr"
  // LAST LINE - DO NOT MOVE THIS COMMENT subTestIdToSubTestName
};

export const testsWithOnlyOneInstructionPageOnOda = [
  "110",
  "119",
  "120",
  "121",
  "122",
  "123",
  "132",
  "138",
  "139",
  "140",
  "141",
  "143",
  "144",
  "145",
  "146",
  "150",
  "151",
  "153",
  "154",
  "158",
  "159",
  "160",
  "161",
  "162",
  "163",
  "164",
  "166",
  "167",
  "168",
  "169",
  "170",
  "171",
  "172",
  "173",
  "174",
  "175",
  "176",
  "179",
  "180",
  "181",
  "182",
  "183",
  "184",
  "188",
  "189",
  "190",
  "191",
  "192",
  "193",
  "194",
  "195",
  "196",
  "197",
  "198",
  "199",
  "200",
  "201",
  "202",
  "203",
  "204",
  "205",
  "206",
  "207",
  "208",
  "209",
  "210",
  "211",
  "212",
  "213",
  "214",
  "215"
  // LAST LINE - DO NOT MOVE THIS COMMENT testsWithOnlyOneInstructionPageOnOda
];

export const getSubdomain = (): string => {
  const hostnameArray = window.location.hostname.split(".");
  const subdomain = hostnameArray[0].toUpperCase();

  return subdomain;
};

/*
 * The date-fns library does not have some of the timezone abbreviations. The best way to make
 * the code maintainable and scalable would be to create custom formatting for the abbreviations.
 * If a new city/timezone is added, we can simply check if there is an abbreviation for it and if not
 * then we apply the same format as below. The main timezone libraries do not have the listed timezones
 * with the correct abbreviation so until then we will need to do this.
 */
export const getCustomTimezoneWithNewAbbreviation = (
  formattedDate: string,
  timezone: string
) => {
  if (formattedDate.includes("UTC")) {
    formattedDate = formattedDate.replace("UTC", "GMT");
  }
  switch (timezone) {
    case "Pacific/Tahiti":
      return formattedDate.replace("GMT-10", "TAHT");
    case "America/Argentina/Buenos_Aires":
      return formattedDate.replace("GMT-3", "ART");
    case "America/Sao_Paulo":
      return formattedDate.replace("GMT-3", "BRT");
    case "Atlantic/Azores":
      if (formattedDate.includes("GMT-1")) {
        return formattedDate.replace("GMT-1", "AZOT");
      }
      return formattedDate.replace("GMT", "AZOST");
    case "Europe/Istanbul":
      return formattedDate.replace("GMT+3", "TRT");
    case "Asia/Dubai":
      return formattedDate.replace("GMT+4", "GST");
    case "Asia/Kabul":
      return formattedDate.replace("GMT+4:30", "AFT");
    case "Asia/Karachi":
      return formattedDate.replace("GMT+5", "PKT");
    case "Asia/Singapore":
      return formattedDate.replace("GMT+8", "SGT");
    case "Indian/Maldives":
      return formattedDate.replace("GMT+5", "MVT");
    case "Asia/Kathmandu":
      return formattedDate.replace("GMT+5:45", "NPT");
    case "Asia/Dhaka":
      return formattedDate.replace("GMT+6", "BST");
    case "Indian/Cocos":
      return formattedDate.replace("GMT+6:30", "CCT");
    case "Asia/Bangkok":
      return formattedDate.replace("GMT+7", "ICT");
    case "Asia/Jakarta":
      return formattedDate.replace("GMT+7", "WIB");
    case "Pacific/Nauru":
      return formattedDate.replace("GMT+12", "NRT");
    case "Australia/Sydney":
      if (formattedDate.includes("GMT+10")) {
        return formattedDate.replace("GMT+10", "AEST");
      }
      return formattedDate.replace("GMT+11", "AEDT");
    case "Pacific/Kiritimati":
      return formattedDate.replace("GMT+14", "LINT");
    case "Pacific/Pago_Pago":
      return formattedDate.replace("GMT-11", "SST");
    case "Europe/Berlin":
    case "Europe/Brussels":
    case "Europe/Madrid":
    case "Europe/Prague":
    case "Europe/Zurich":
    case "Europe/Paris":
      if (formattedDate.includes("GMT+2")) {
        return formattedDate.replace("GMT+2", "CEST");
      }
      return formattedDate.replace("GMT+1", "CET");
    case "Europe/Helsinki":
      if (formattedDate.includes("GMT+3")) {
        return formattedDate.replace("GMT+3", "EEST");
      }
      return formattedDate.replace("GMT+2", "EET");
    case "Asia/Calcutta":
      return formattedDate.replace("GMT+5:30", "IST");
    case "Asia/Hong_Kong":
      return formattedDate.replace("GMT+8", "HKT");
    case "Asia/Tokyo":
      return formattedDate.replace("GMT+9", "JST");
    case "Australia/Darwin":
    case "Australia/Adelaide":
      return formattedDate.replace("GMT+9:30", "ACST");
    case "Australia/Perth":
      return formattedDate.replace("GMT+8", "AWST");
    case "Australia/Brisbane":
      return formattedDate.replace("GMT+10", "AEST");
    case "Pacific/Auckland":
      if (formattedDate.includes("GMT+12")) {
        return formattedDate.replace("GMT+12", "NZST");
      }
      return formattedDate.replace("GMT+13", "NZDT");
    case "Europe/London":
      return formattedDate.replace("GMT+1", "BST");
    case "Africa/Lagos":
      return formattedDate.replace("GMT+1", "WAT");
    case "Asia/Shanghai":
      return formattedDate.replace("GMT+8", "CST");
    case "America/Bogota":
      return formattedDate.replace("GMT-5", "COT");
    case "America/Caracas":
      return formattedDate.replace("GMT-4", "VET");
    case "Asia/Seoul":
      return formattedDate.replace("GMT+9", "KST");
    default:
      return formattedDate;
  }
};

const englishTranslatedText = {
  warningText3:
    "For test security reasons, you cannot restart this event again and will need to contact our support team to have your access reset. They can be reached on the bottom right hand corner of ##URL##",
  connectionIssue:
    "For an optimal experience, please ensure your device has a reliable internet connection. If you experience an Internet connection issue and need to restart your assessment, you can go to ##URL## and enter in your Event ID.",
  testIsNonMobile:
    "You appear to be using a mobile device. This assessment is not currently supported on mobile browsers. Please take this assessment on a desktop computer. To take this test, go to ##URL## on a computer, and enter the following Event ID:{[EVENT_ID]}",
  nonMobileInstructionPage:
    "It looks like you are using a mobile device. This assessment is not currently supported on mobile browsers. Please take this assessment on a computer.\r\nTo perform this test, go to ##URL## on a computer and enter the following event ID: {[EVENT_ID]}",
  unsupportedBrowser:
    "This assessment is not currently supported on your browser. Please use Chrome, FireFox, or Safari to complete this assessment.\nTo take this assessment, go to ##URL## on a compatible browser, and enter the following Event ID.\nEVENT ID: {[EVENT_ID]}",
  errorBeginning:
    "There was an error beginning your assessment. Please forward the link you used to access the assessment to our support team. They can be reached on the bottom right hand corner of ##URL##"
};

export type RevelianErrorType =
  | "warningText3"
  | "connectionIssue"
  | "testIsNonMobile"
  | "nonMobileInstructionPage"
  | "unsupportedBrowser"
  | "errorBeginning"
  | null;

export const getTranslatedTextWithURL = (
  context: any,
  textKey: RevelianErrorType
): string => {
  if (textKey === null) {
    return "";
  }

  const stockText =
    typeof context?.testEventData?.translatedText?.[textKey] === "string"
      ? context.testEventData.translatedText[textKey]
      : englishTranslatedText[textKey];
  return stockText.replace(/##URL##/g, window.location.host);
};

export const getRedirectUrlForExitPage = (data: any) => {
  let url = "";
  if (
    data.error === "reused result" ||
    data.eventStatus === "completed" ||
    Object.keys(data).includes("reused")
  ) {
    if (data.returnUrl) {
      url = data.returnUrl;
    } else if (data.customExitPageType === "redirect") {
      url = data.redirectUrl;
    }
  }
  return url;
};

export const getReusedResultsCaseWithRawData = (data: any) => {
  if (
    data.error === "reused result" ||
    data.eventStatus === "completed" ||
    Object.keys(data).includes("reused")
  ) {
    if (data.error === "reused result" || data.reused) return 2;
    else return 1;
  }
  return 0;
};

export const getReusedResultsCaseWithDecryptedData = (decryptedData: any) => {
  // If reused field is returned, can exit early
  if (Object.keys(decryptedData).includes("reused")) {
    return 3;
  }

  // Illustrait takes longer to score to be marked as event completed in the BE
  // if the testEvent contains Illustrait, we have to do a special check here
  // this is to direct the candidates to event complete page when the candidates have completed the testEvent on ODA and BE has not yet marked the testEvent as completed
  const hasIllustrait =
    decryptedData.tests && Object.keys(decryptedData.tests).indexOf("132") >= 0;

  const allTestsCompleted =
    decryptedData.tests &&
    Object.keys(decryptedData.tests).reduce((completed, testId) => {
      if (!decryptedData.tests[testId].completed) {
        return false;
      }
      return completed;
    }, true);

  // If VI comes after Illustrait, avoid reused results flow so candidate can proceed with VI
  const videoInterviewAfterAssessments =
    decryptedData.sortOrder && decryptedData.sortOrder[1] === "Video Interview";

  if (hasIllustrait && allTestsCompleted && !videoInterviewAfterAssessments) {
    return 1;
  }

  return 0;
};

export const phoneCountryCodes: { [key: string]: string } = {
  US: "+1",
  AZ: "+61",
  NZ: "+64"
};

// may need to handle other countries in the future but for now we can use this until then
export const formatPhoneNumber = (value: string, countryCode: string) => {
  // if the value is empty without the country code, return the empty string
  const phoneNumberWithoutCountryCode = value.replace(`${countryCode} `, "");

  if (!phoneNumberWithoutCountryCode) {
    return phoneNumberWithoutCountryCode;
  }

  const phoneNumber = phoneNumberWithoutCountryCode.replace(/[^.\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) {
    return `${countryCode} ${phoneNumber}`;
  }

  if (phoneNumberLength < 7) {
    return `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3
    )}`;
  }

  return `${countryCode} (${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
};
