import Language from "../../Interfaces/Language";

export const zh_cn: Language = {
  help: "需要帮助？",
  helpCenter: "帮助中心",
  expect: "在这些测试中会出现什么情况？",
  faq: "常见问题",
  faqLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853529-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98",
  copyright: "版权所有",
  copyrightNotices: "版权声明",
  copyrightNoticesLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994366-%E7%89%88%E6%9D%83%E5%A3%B0%E6%98%8E",
  terms: "使用条款",
  termsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994198-%E4%BD%BF%E7%94%A8%E6%9D%A1%E6%AC%BE%E5%92%8C%E6%9D%A1%E4%BB%B6",
  privacy: "隐私声明",
  privacyLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990017-%E9%9A%90%E7%A7%81%E7%AD%96%E7%95%A5",
  dmca: "DMCA政策",
  dmcaLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7856746-%E6%95%B0%E5%AD%97%E5%8D%83%E5%B9%B4%E7%89%88%E6%9D%83%E6%B3%95%E6%94%BF%E7%AD%96",
  accommodations: "残障人士/便利设施",
  accommodationsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994433-%E6%AE%8B%E7%96%BE-%E6%97%A0%E9%9A%9C%E7%A2%8D",
  technicalSupport: "技术支持",
  chatSupport: "如需支持，请在本页右下角与我们的团队聊天。"
};
