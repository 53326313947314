import Language from "../../Interfaces/Language";

export const pt_br: Language = {
  help: "Precisa de ajuda?",
  helpCenter: "Central de Ajuda",
  expect: "O que esperar desses testes",
  faq: "Perguntas frequentes",
  faqLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853478-perguntas-frequentes",
  copyright: "Diretos autorais",
  copyrightNotices: "Avisos de Direitos Autorais",
  copyrightNoticesLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994361-aviso-de-direitos-autorais",
  terms: "Termos de Uso",
  termsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994193-termos-e-condicoes-de-uso",
  privacy: "Declaração de Privacidade",
  privacyLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990048-politica-de-privacidade",
  dmca: "Política de DMCA",
  dmcaLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853802-politica-do-digital-millenium-copyright-act",
  accommodations: "Necessidades especiais/adequações",
  accommodationsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994428-necessidades-especiais-adequacoes",
  technicalSupport: "Suporte técnico",
  chatSupport:
    "Se precisar de ajuda, entre em contato com nossa equipe de suporte no canto inferior direito desta página"
};
