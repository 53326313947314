import React, { useContext } from "react";
import PageContinueButton from "./PageContinueButton";
import { TestEventContext } from "../Contexts/TestEventContext";

interface Props {
  researchLink: string;
  isMarketingODAResearchEnabled: boolean;
}

// May consider changing to switch statements if more research links are added and text is drastically changed.
export const JoinResearch = (props: Props) => {
  const context = useContext(TestEventContext);
  const { researchLink, isMarketingODAResearchEnabled } = props;
  const handleOnClick = () => {
    window.location.href = researchLink;
  };
  return (
    <div className="mt-5">
      <h3 className="mb-3">Join Criteria's Research</h3>
      <div className="mb-3"></div>
      {isMarketingODAResearchEnabled ? (
        <p>
          {context?.testEventData?.translatedText?.marketResearchEnabled
            ? context.testEventData.translatedText.marketResearchEnabled
            : "We would love your help in our efforts to learn more about the candidate job market."}
        </p>
      ) : (
        <p>
          {context?.testEventData?.translatedText?.marketResearchDisabled
            ? context.testEventData.translatedText.marketResearchDisabled
            : "We would love your help in our continued efforts to produce high quality assessments."}
        </p>
      )}
      <ul>
        <li>
          This is completely{" "}
          <strong>
            <u>optional</u>
          </strong>{" "}
          and for{" "}
          {isMarketingODAResearchEnabled
            ? context?.testEventData?.translatedText?.researchPurpose
              ? context.testEventData.translatedText.researchPurpose
              : "research purposes only."
            : context?.testEventData?.translatedText?.researchAndDevPurpose
            ? context.testEventData.translatedText.researchAndDevPurpose
            : "research and development purposes only."}
        </li>
        {context?.testEventData?.translatedText?.surveyList ? (
          context.testEventData.translatedText.surveyList
        ) : (
          <>
            <li>
              This survey is{" "}
              <strong>
                <u>not</u>
              </strong>{" "}
              related to your assessment process.
            </li>
            <li>
              Your individual{" "}
              {isMarketingODAResearchEnabled ? "responses" : "results"} in this
              research are anonymous and will never be released to employers.
            </li>
          </>
        )}
      </ul>
      <p className="mb-5">
        {context?.testEventData?.translatedText?.researchThankYou ? (
          context.testEventData.translatedText.researchThankYou
        ) : (
          <>
            Thank you for your help. To begin this{" "}
            <strong>
              <u>optional</u>
            </strong>{" "}
            survey, please click the button below.
          </>
        )}
      </p>
      <div className="mt-3">
        <PageContinueButton
          handler={handleOnClick}
          buttonText={
            context?.testEventData?.translatedText?.clickToBegin
              ? context.testEventData.translatedText.clickToBegin
              : "Click here to begin"
          }
        />
      </div>
    </div>
  );
};
