import { v4 as uuidv4 } from "uuid";
import { TestEventContext } from "../Contexts/TestEventContext";
import { odaURL } from "./constants";
import setUpWebVitalsMetrics from "./core-web-vitals";

// Use a relative URL for the API, to accommodate use of different ODA domains without CORS.
const telemetryHost = odaURL.includes("localhost") ? odaURL : "";
const telemetryApiUrl = `${telemetryHost}/api/cx/telemetry`;

type TelemetryEventContext = {
  "criteria.eventId"?: string;
  "criteria.testEventId"?: number;
  "criteria.testTakerId"?: number;
  "criteria.companyAccountId"?: string;
  "criteria.jobCode"?: string;
  "criteria.token"?: string;
  "criteria.partnerAccountId"?: string;
  "criteria.testIdsArray"?: string[];
  "criteria.isResumingTest"?: boolean;
  user?: string;
};

type TelemetryEvent = {
  timestamp: string;
  "trace.trace_id": string;
  "trace.parent_id"?: string;
  "trace.span_id": string;
  name: string;
  duration_ms: number;
  "url.full": string;
  "url.pageload": string;
  [key: string]: any;
} & TelemetryEventContext;

const traceId = uuidv4();
const rootSpanId = uuidv4();
const pageloadUrl = window.location.href;

// The queue stores not-yet-sent telemetry events, to be flushed in batches.  It starts with an
// event representing the page load, as the root span.
const queue = new Set<TelemetryEvent>([
  {
    timestamp: new Date().toISOString(),
    "trace.trace_id": traceId,
    "trace.span_id": rootSpanId,
    name: `pageload ${window.location.pathname}`,
    duration_ms: 0,
    "url.full": window.location.href,
    "url.pageload": pageloadUrl
  }
]);

export const addTelemetryEvent = (event: {
  name: string;
  duration_ms?: number;
  [key: string]: any;
}): void => {
  const duration_ms = event.duration_ms ?? 0;
  queue.add({
    ...event,
    timestamp: new Date(Date.now() - duration_ms).toISOString(),
    "trace.trace_id": traceId,
    "trace.parent_id": rootSpanId,
    "trace.span_id": uuidv4(),
    duration_ms,
    "url.full": window.location.href,
    "url.pageload": pageloadUrl
  });
};

// We constantly capture changes to the test event context, and when we flush the queue, add the
// _latest_ context data to all the telemetry events we're flushing. That way older events like the
// root span can still be associated with eg the companyAccountId, even though we don't find out
// the companyAccountId until after the root span's been created. This relies on the fact that none
// of the `TelemetryEventContext` values will ever change from one non-null value to a different
// non-null value in a given page load: only ever from null/undefined to a non-null value.
let currentContext: TestEventContext | null;
let lastPageLocation = window.location.href;
let rootSpanSent = false;

export const telemetrySetup = () => {
  setUpWebVitalsMetrics();
  setupVisibilityHandler();
  setUpErrorHandling();
};

export const telemetryHandler = (updatedContext: TestEventContext) => {
  currentContext = updatedContext;

  let sawNavigation = false;
  if (lastPageLocation !== window.location.href) {
    sawNavigation = true;
    addTelemetryEvent({
      name: `navigate ${window.location.pathname}`,
      "url.previous": lastPageLocation
    });
    lastPageLocation = window.location.href;
  }

  // We wait until we have populated test event context before we first flush the queue, so
  // that the root span and core web vitals telemetry, etc, can have attributes like
  // companyAccountId populated. After that, we flush on navigation.
  const shouldFlush = !rootSpanSent
    ? !!currentContext.testEventId
    : sawNavigation;
  if (shouldFlush) {
    flushQueue();
  }
};

// Get the context data to merge in to a telemetry event, avoiding keys with null/undefined values.
const formatContextData = ({
  eventId,
  testEventId,
  testEventData,
  jobCode,
  token,
  companyAccountId,
  partnerAccountId,
  testIdsArray,
  isResumingTest
}: TestEventContext): TelemetryEventContext => {
  const result: TelemetryEventContext = {};

  if (eventId) {
    result["criteria.eventId"] = eventId;
    result.user = eventId;
  }

  if (testEventId) result["criteria.testEventId"] = testEventId;
  if (testEventData?.testTaker?.testTakerId) {
    result["criteria.testTakerId"] = testEventData.testTaker.testTakerId;
  }
  if (jobCode) result["criteria.jobCode"] = jobCode;
  if (token) result["criteria.token"] = token;
  if (companyAccountId) result["criteria.companyAccountId"] = companyAccountId;
  if (partnerAccountId) result["criteria.partnerAccountId"] = partnerAccountId;
  if (testIdsArray) result["criteria.testIdsArray"] = testIdsArray;
  if (isResumingTest !== undefined) {
    result["criteria.isResumingTest"] = isResumingTest;
  }

  return result;
};

const postData = async (
  updatedQueue: TelemetryEvent[]
): Promise<void | Error> => {
  try {
    const body = JSON.stringify(Array.from(updatedQueue));
    // Use navigator.sendBeacon if we're being backgrounded/closed, to improve the likelihood of
    // delivery. Use fetch otherwise, to improve compatibility and reduce the likelihood of an
    // ad blocker blocking the call.
    if (!document.hidden || !navigator.sendBeacon) {
      const response = await fetch(telemetryApiUrl, {
        method: "POST",
        body: body
      });
      !response.ok && console.error("Failed to send telemetry data");
    } else {
      const blob = new Blob([body], { type: "application/json" });
      if (!navigator.sendBeacon(telemetryApiUrl, blob)) {
        console.error("Failed to send telemetry data");
      }
    }
  } catch (error) {
    console.error("cx error: ", error);
    return error as Error;
  }
};

let flushingQueue = false;

const flushQueue = async () => {
  // If we're in the middle of sending, don't start another one. We'll automatically flush
  // newly-added events at the end of this function anyway.
  if (flushingQueue || queue.size === 0) {
    return;
  }

  flushingQueue = true;
  const contextData = currentContext ? formatContextData(currentContext) : {};
  const queueToSend = Array.from(queue).map(entry => ({
    ...entry,
    ...contextData
  }));
  queue.clear();

  const result = await postData(queueToSend);
  if (result instanceof Error) {
    queueToSend.forEach(entry => queue.add(entry));
  }

  rootSpanSent = true;
  flushingQueue = false;

  // Immediately send any additional events that came in while we were sending.
  if (queue.size > 0) {
    flushQueue();
  }
};

const setUpErrorHandling = () => {
  const STOPPING_RULE = 50;
  let errorCount = 0;

  const shouldProcessError = () => errorCount <= STOPPING_RULE;
  const incrementErrorCount = () => errorCount++;

  // Helper function to record telemetry for errors
  const addTelemetryForError = (
    message: string,
    stack: any,
    source: string
  ) => {
    if (shouldProcessError()) {
      addTelemetryEvent({
        name: `error ${window.location.pathname}`,
        "error.message": message,
        "error.stack": stack,
        "error.source": source
      });
      incrementErrorCount();
    }
  };

  // Overriding console.error to capture and record telemetry for explicit error logs
  const originalError = console.error.bind(console);
  console.error = function (message: string, ...optionalParams: any) {
    addTelemetryForError(message, optionalParams, "console");
    originalError(message, ...optionalParams);
  };

  // Global error listener to capture and record telemetry for uncaught errors
  window.addEventListener("error", function (event) {
    addTelemetryForError(event.error.message, event.error.stack, "error");
  });

  // Global listener for unhandled promise rejections to capture and record telemetry
  window.addEventListener("unhandledrejection", function (event) {
    const reason = event.reason;
    addTelemetryForError(reason.message, reason.stack, "unhandledrejection");
  });
};

const setupVisibilityHandler = () => {
  // Always flush the queue when visibility changes, because we might never get another chance.
  const visibilityHandler = () => {
    if (document.hidden) {
      flushQueue();
    }
  };
  document.addEventListener("visibilitychange", visibilityHandler);
  // For Safari support:
  document.addEventListener("pagehide", () => {
    flushQueue();
  });
};
