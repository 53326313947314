import Language from "../Interfaces/Language";
import { en_us } from "../Resources/languages/en_us";
import { en_au } from "../Resources/languages/en_au";
import { turkish } from "../Resources/languages/turkish";
import { korean } from "../Resources/languages/korean";
import { fr_ca } from "../Resources/languages/fr_ca";
import { fr_fr } from "../Resources/languages/fr_fr";
import { es_la } from "../Resources/languages/es_la";
import { es_es } from "../Resources/languages/es_es";
import { pt_br } from "../Resources/languages/pt_br";
import { pt_pt } from "../Resources/languages/pt_pt";
import { zh_cn } from "../Resources/languages/zh_cn";
import { german } from "../Resources/languages/german";
import { japanese } from "../Resources/languages/japanese";
import { italian } from "../Resources/languages/italian";

/**
 * @desc Reads test event data to return the language needed for translation.
 * @desc NOTE: This function is temporary/subject to change if we deprecate reliance on DB translatedText values.
 * @param testEventData Object of test event data which may have translatedText and locale values.
 * @param isAU Optional boolean taken from TestEventContext to determine Australian English translation.
 * @return {string} Returns string language name or code.
 */
export const getTranslationLanguage = (
  testEventData: any,
  isAU: boolean = false
): string => {
  const hostnameArray = window.location.hostname.split(".");
  const subdomain = hostnameArray[0].toUpperCase();

  const testEventLanguage = testEventData?.translatedText?.language;

  if (testEventLanguage && testEventLanguage !== "english") {
    return testEventLanguage;
  }

  if (testEventData?.locale === "AU" || isAU || subdomain === "AU") {
    return "au";
  }

  return "en-us";
};

/**
 * @desc Takes the name of a text property and returns its value in the appropriate language.
 * @param textKey String "name" of a text value to be found as a key in a language file, ex. "helpCenter".
 * @param language Optional string param of a language code to translate to, defaults to "en-us" if not passed in.
 * @return {string | undefined} Returns string text value if found in a language file, otherwise empty string or undefined.
 */
export const translate = (
  textKey: string,
  language: string = "en-us"
): string | undefined => {
  let langFile = en_us;

  switch (language.toLowerCase()) {
    case "au":
    case "en-au":
    case "english-australia":
      langFile = en_au;
      break;
    case "turkish":
    case "tr":
      langFile = turkish;
      break;
    case "ko":
    case "korean":
      langFile = korean;
      break;
    case "french-canada":
    case "fr-ca":
      langFile = fr_ca;
      break;
    case "french":
    case "french-france":
    case "fr-fr":
      langFile = fr_fr;
      break;
    case "spanish":
    case "es-la":
      langFile = es_la;
      break;
    case "spanish-spain":
    case "es-es":
      langFile = es_es;
      break;
    case "portuguese-brazil":
    case "pt-br":
      langFile = pt_br;
      break;
    case "portuguese":
    case "pt-pt":
      langFile = pt_pt;
      break;
    case "chinese":
    case "zh-cn":
      langFile = zh_cn;
      break;
    case "german":
    case "de":
      langFile = german;
      break;
    case "japanese":
    case "ja":
      langFile = japanese;
      break;
    case "italian":
    case "it":
      langFile = italian;
      break;
    case "english":
    case "us":
    case "en-us":
    default:
      langFile = en_us;
      break;
  }

  if (!langFile.hasOwnProperty(textKey) && langFile !== en_us) {
    // If a specific translation could not be found, try defaulting back to English again
    if (en_us.hasOwnProperty(textKey)) {
      langFile = en_us;
    } else {
      // If still not found, return empty string
      return "";
    }
  }

  return langFile[textKey as keyof Language];
};
