import Language from "../../Interfaces/Language";

export const italian: Language = {
  help: "Hai bisogno di aiuto?",
  helpCenter: "Centro assistenza",
  expect: "Cosa aspettarti da questi test",
  faq: "Domande frequenti",
  faqLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7853719-domande-frequenti",
  copyright: "Copyright",
  copyrightNotices: "Avvisi sul copyright",
  copyrightNoticesLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994379-copyright",
  terms: "Termini d'uso",
  termsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994307-termini-e-condizioni-d-uso",
  privacy: "Informativa sulla privacy",
  privacyLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6990123-informativa-sulla-privacy",
  dmca: "Politica DMCA",
  dmcaLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/7857144-politica-del-digital-millenium-copyright-act",
  accommodations: "Disabilità/Assistenza",
  accommodationsLink:
    "https://intercom.help/criteria-corp-f7a8bb8a1f89/en/articles/6994444-disabilita-accessibilita",
  technicalSupport: "Assistenza tecnica",
  chatSupport:
    "Per ricevere assistenza accedi alla chat che si trova in basso a destra di questa pagina"
};
