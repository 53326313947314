import React, { useState, useContext } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import checkCircle from "../Resources/images/check_circle.png";
import { TestEventContext } from "../Contexts/TestEventContext";
import Footer from "./Footer";
import Header from "./Header";
import useInterval from "../CustomHooks/useInterval";

interface Props {
  reusedResults?: boolean;
}

const TestAlreadyCompleted = ({ reusedResults }: Props) => {
  const context: any = useContext(TestEventContext);
  const url = context ? context.optionalReturnUrl : null;
  const [seconds, setSeconds] = useState(5);

  // on mount only, set the interval to count down the seconds, and clean up on unmount
  useInterval(() => {
    const timeRemaining = seconds - 1;
    if (timeRemaining === 0 && url) {
      window.location.href = url;
    } else {
      setSeconds(timeRemaining);
    }
  }, 1000);

  return (
    <Container>
      <Row>
        <Col
          xl={{ span: 8, offset: 2 }}
          lg={{ span: 8, offset: 2 }}
          md={{ span: 10, offset: 1 }}
          sm={12}
        >
          <Card className="oda-card">
            <Header onWelcomePage={true} backgroundColor="white" />
            <Card.Body>
              <Card.Title
                as="h1"
                className="text-center h5"
                style={{ fontWeight: "bold" }}
              >
                Thank you.
              </Card.Title>
              <Row className="justify-content-center">
                <img className="mb-4 text-center" src={checkCircle} alt="" />
              </Row>
              {reusedResults ? (
                <>
                  {context?.testEventData?.translatedText?.reusedResults1 ? (
                    context.testEventData.translatedText.reusedResults1
                  ) : (
                    <>
                      <p>
                        This company already has your assessment results
                        recorded from a previous session. You do not have to
                        complete the assessments again.
                      </p>
                      <p>No further action is required.</p>
                    </>
                  )}
                </>
              ) : (
                <>
                  {context?.testEventData?.translatedText?.reusedResults2 ? (
                    context.testEventData.translatedText.reusedResults2
                  ) : (
                    <>
                      <p>
                        Our records show that you have already completed all of
                        the assessments.
                      </p>
                      <p>No further action is required.</p>
                    </>
                  )}
                </>
              )}
              {url && (
                <p>
                  {context?.testEventData?.translatedText?.redirect
                    ? context.testEventData.translatedText.redirect.replace(
                        "{seconds}",
                        seconds
                      )
                    : `Please wait. You will be redirected in ${seconds} seconds...`}
                </p>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Footer />
    </Container>
  );
};

export default TestAlreadyCompleted;
