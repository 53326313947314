import React, { useContext, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import Header from "../Components/Header";
import PageContinueButton from "../Components/PageContinueButton";
import { TestEventContext } from "../Contexts/TestEventContext";
import LandingPageStyle from "../Interfaces/LandingPageStyle";
import useWindowSize from "../CustomHooks/useWindowSize";
import { navigate } from "@reach/router";

interface Props {
  continueButtonHandler: () => void;
  landingPageStyle: LandingPageStyle;
  handleUpdateComponentShowing(componentName: string): void;
}

const WelcomePage = ({
  landingPageStyle,
  continueButtonHandler,
  handleUpdateComponentShowing
}: Props) => {
  const context = useContext(TestEventContext);

  useEffect(() => {
    handleUpdateComponentShowing("Welcome");
  }, [handleUpdateComponentShowing]);

  const isAULocale =
    context && context.testEventData.locale
      ? context.testEventData.locale === "AU"
      : null;

  const landingPageIsDefault =
    context && context.testEventData.landingPage
      ? context.testEventData.landingPage.isDefault === 2
      : null;

  // we need to determine if this is an event that was started via a Talent Insights invite or
  // pending action in Talent Insights click.  If we, we want to display the link to connect the
  // candidates previous assessments (if applicable).
  const showConnectCandidateLink =
    context?.testEventData?.teamingUser?.allowLinkResults === 1;

  // style variable used for the connect candidates button to make it appear as a link.
  const linkButtonStyle: any = {
    color: "#425cc7",
    textDecoration: "underline",
    fontWeight: "bold",
    backgroundColor: "#FFF",
    border: "0",
    margin: 0,
    padding: 0
  };

  const handleLinkClick = () => {
    navigate("/link-results");
  };

  // to keep clarity to the welcome message, and not have it get to close the card body border,
  // we need to check the screen size and set the padding accordingly.
  const windowSize = useWindowSize();
  const paddingValue =
    windowSize &&
    (windowSize >= 768
      ? "60px"
      : windowSize < 768 && windowSize > 375
      ? "24px"
      : windowSize <= 375
      ? "16px"
      : "16px");

  const assessments = context?.testEventData.tests;

  const numberOfAssessments: number | null = assessments
    ? Object.keys(assessments).length
    : null;

  let dynamicInvitationMsg: string =
    context &&
    context.testEventData.translatedText &&
    context.testEventData.translatedText.invitationMsg;

  if (numberOfAssessments === null) {
    dynamicInvitationMsg =
      context &&
      context.testEventData.translatedText &&
      context.testEventData.translatedText.invitationMsgNoTest;
  } else if (numberOfAssessments === 1) {
    dynamicInvitationMsg =
      context &&
      context.testEventData.translatedText &&
      context.testEventData.translatedText.invitationMsgOneTest;
  }

  const defaultContinueMessage = (
    <Row>
      <Col>
        <p>If you have your Event ID, enter this on the next page to progress.</p>
      </Col>
    </Row>
  );
  let defaultWelcomeMessage;
  if (numberOfAssessments === null) {
    defaultWelcomeMessage = (
      <React.Fragment>
        <Row>
          <Col>
            <p>If you have been sent an invitation to complete an assessment, then please follow the link provided in your invitation to continue.</p>
          </Col>
        </Row>
        {defaultContinueMessage}
      </React.Fragment>
    );
  } else {
    defaultWelcomeMessage = (
      <React.Fragment>
        <Row>
          <Col>
            <p>{`You have been invited to take ${
              numberOfAssessments === 1 ? "an assessment" : "assessments"
            }!`}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              This gives you the opportunity to demonstrate your abilities in a
              fair and objective way.
            </p>
          </Col>
        </Row>
        {defaultContinueMessage}
      </React.Fragment>
    );
  }

  return (
    <Card className="oda-card">
      <Header
        onWelcomePage
        backgroundColor={landingPageStyle.backgroundColor}
        customLogo={context?.testEventData.logo}
        isVideoInterviewDiscoveryJob={context?.isVideoInterviewDiscoveryJob}
      />
      <Card.Body
        style={{
          backgroundColor: landingPageStyle.backgroundColor,
          color: landingPageStyle.textColor,
          paddingLeft: paddingValue,
          paddingRight: paddingValue
        }}
      >
        <Card.Title
          className="text-center h5"
          as="h1"
          style={{
            color: landingPageStyle.welcomeColor
          }}
        >
          {context &&
          context.isVideoInterviewDiscoveryJob &&
          context.videoInterviewDiscovery?.welcome ? (
            <strong>{context.videoInterviewDiscovery.welcome}</strong>
          ) : !landingPageIsDefault ? (
            <strong>{landingPageStyle.welcomeMessage}</strong>
          ) : context && context.testEventData.translatedText ? (
            <p
              dangerouslySetInnerHTML={{
                __html: context.testEventData.translatedText.welcome
              }}
            />
          ) : (
            <strong>
              Welcome to the Criteria Assessment{" "}
              {isAULocale ? "Centre" : "Center"}
            </strong>
          )}
        </Card.Title>
        {landingPageStyle.landingPageVideo ? (
          <div
            className="video-wrapper"
            dangerouslySetInnerHTML={{
              __html: landingPageStyle.landingPageVideo
            }}
          />
        ) : null}
        {context &&
        context.isVideoInterviewDiscoveryJob &&
        context.videoInterviewDiscovery ? (
          <React.Fragment>
            <p>{context.videoInterviewDiscovery?.invitationMsg}</p>
            <p>{context.videoInterviewDiscovery?.invitationDescription}</p>
          </React.Fragment>
        ) : landingPageStyle.instructions ? (
          <div
            dangerouslySetInnerHTML={{
              __html: landingPageStyle.instructions
            }}
          />
        ) : context && context.testEventData.translatedText ? (
          <div
            dangerouslySetInnerHTML={{
              __html: dynamicInvitationMsg
            }}
          />
        ) : (
          <div>{defaultWelcomeMessage}</div>
        )}
      </Card.Body>
      <Card.Footer
        style={{
          borderTop: 0,
          backgroundColor: landingPageStyle.backgroundColor
        }}
      >
        <PageContinueButton
          handler={continueButtonHandler}
          buttonText={
            context && context.testEventData.translatedText
              ? context.testEventData.translatedText.continueButton
              : "Continue"
          }
        />
        {showConnectCandidateLink ? (
          <p className="text-center mt-4">
            Already taken the Criteria personality test?{" "}
            <button style={linkButtonStyle} onClick={handleLinkClick}>
              Click here
            </button>{" "}
            to link your results to your profile.
          </p>
        ) : null}
      </Card.Footer>
    </Card>
  );
};

export default WelcomePage;
